import React from 'react'
import { Row, Col } from 'antd'
import LinkCard from './LinkCard'

// import icons individually for better dev build performance
import UploadOutlined from '@ant-design/icons/UploadOutlined'
import CloudDownloadOutlined from '@ant-design/icons/CloudDownloadOutlined'
import UsergroupAddOutlined from '@ant-design/icons/UsergroupAddOutlined'
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined'
import CompassOutlined from '@ant-design/icons/CompassOutlined'
import EnvironmentOutlined from '@ant-design/icons/EnvironmentOutlined'
import SearchOutlined from '@ant-design/icons/SearchOutlined'
import UserOutlined from '@ant-design/icons/UserOutlined'

const colProps = {
  xs: 24,
  sm: 12,
  md: 6
}

const MapHubsProLinks = (): JSX.Element => {
  return (
    <div
      style={{
        width: '100%',
        backgroundColor: process.env.NEXT_PUBLIC_PRIMARY_COLOR,
        backgroundImage: 'url(/topo3.png)',
        backgroundBlendMode: 'screen',
        border: '1px solid #e8e8e8'
      }}
    >
      <Row
        style={{
          width: '100%',
          maxWidth: '1024px',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '16px'
        }}
        gutter={[16, 16]}
      >
        <Col {...colProps}>
          <LinkCard title='Maps' href='/maps' icon={<CompassOutlined />} />
        </Col>
        <Col {...colProps}>
          <LinkCard
            title='Layers'
            href='/layers'
            icon={<EnvironmentOutlined />}
          />
        </Col>
        <Col {...colProps}>
          <LinkCard title='Groups' href='/groups' icon={<UserOutlined />} />
        </Col>
        <Col {...colProps}>
          <LinkCard title='Search' href='/search' icon={<SearchOutlined />} />
        </Col>
      </Row>
      <Row
        style={{
          width: '100%',
          maxWidth: '1024px',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '16px',
          marginBottom: '16px'
        }}
        gutter={[16, 16]}
      >
        <Col {...colProps}>
          <LinkCard
            title='Create a Map'
            href='/map/new'
            icon={<PlusCircleOutlined />}
          />
        </Col>
        <Col {...colProps}>
          <LinkCard
            title='Create a Layer'
            href='/create/layer'
            icon={<UploadOutlined />}
          />
        </Col>
        <Col {...colProps}>
          <LinkCard
            title='Import MapHubs File'
            href='/layer/import'
            icon={<CloudDownloadOutlined />}
          />
        </Col>
        <Col {...colProps}>
          <LinkCard
            title='Create a Group'
            href='/create/group'
            icon={<UsergroupAddOutlined />}
          />
        </Col>
      </Row>
    </div>
  )
}
export default MapHubsProLinks
