import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import Layout from '@/components/Layout/LayoutSSR'
import { getSession } from '@maphubs/auth'
import { GetServerSideProps } from 'next'
import { Row, Col, Divider, Typography, Card } from 'antd'
import { CardCarousel, CardUtil } from '@maphubs/ui'
import Slides from '@/components/Home/Slides'
import OnboardingLinks from '@/components/Home/OnboardingLinks'
import MapHubsProLinks from '@/components/Home/MapHubsProLinks'
import _shuffle from 'lodash.shuffle'
import ErrorBoundary from '@/components/ErrorBoundary'
import useT from '@/hooks/useT'
import HomePageMap from '@/components/Home/HomePageMap'
import HomePageButton from '@/components/Home/HomePageButton'
import { Map, Group, Layer, LocalizedString } from '@maphubs/mhtypes'
import dayjs from 'dayjs'

// SSR only
import { getPageConfigs } from '@/models/page'
import { getFeaturedGroups, getRecentGroups } from '@/models/group'
import {
  getFeaturedLayers,
  getPopularLayers,
  getRecentLayers
} from '@/models/layer'
import { getFeaturedMaps, getRecentMaps } from '@/models/map'
import { HeaderConfig } from '@/components/header'
import { FooterConfig } from '@/components/footer'

const { Title } = Typography

type Props = {
  featuredLayers?: Layer[]
  popularLayers?: Layer[]
  recentLayers?: Layer[]
  featuredGroups?: Group[]
  recentGroups?: Group[]
  featuredMaps?: Map[]
  recentMaps?: Map[]
  pageConfig?: PageConfig
  headerConfig?: HeaderConfig
  footerConfig?: FooterConfig
}
type State = {
  loaded: boolean
}

type PageConfig = {
  components: Array<any>
  disableFooter?: boolean
  title?: string
}

// use SSR for SEO
export const getServerSideProps: GetServerSideProps = async (context) => {
  const result = await getPageConfigs(['home', 'header', 'footer'])
  const pageConfig = result?.home || { components: [] }
  const headerConfig = result?.header || {}
  const footerConfig = result?.footer || {}

  const results: Props = {}

  if (
    Array.isArray(pageConfig.components) &&
    pageConfig.components.length > 0
  ) {
    await Promise.all(
      pageConfig.components.map(async (component: Record<string, any>) => {
        switch (component.type) {
          case 'storyfeed': {
            if (component.config) {
              // TODO: implement Ghost storyfeed
            }
            break
          }
          case 'carousel': {
            if (
              component.datasets &&
              Array.isArray(component.datasets) &&
              component.datasets.length > 0
            ) {
              await Promise.all(
                component.datasets.map(async (dataset) => {
                  const { type, filter, max, tags } = dataset
                  const number = max || 6

                  switch (type) {
                    case 'layer': {
                      switch (filter) {
                        case 'featured': {
                          results.featuredLayers = await getFeaturedLayers(
                            number
                          )
                          break
                        }
                        case 'popular': {
                          results.popularLayers = await getPopularLayers(number)
                          break
                        }
                        case 'recent': {
                          results.recentLayers = await getRecentLayers(number)
                          break
                        }
                        // No default
                      }
                      break
                    }
                    case 'group': {
                      switch (filter) {
                        case 'featured': {
                          results.featuredGroups = await getFeaturedGroups(
                            number
                          )

                          break
                        }
                        case 'recent': {
                          results.recentGroups = await getRecentGroups(number)
                          break
                        }
                        // No default
                      }

                      break
                    }
                    case 'map': {
                      switch (filter) {
                        case 'featured': {
                          results.featuredMaps = await getFeaturedMaps(number)
                          break
                        }
                        case 'recent': {
                          results.recentMaps = await getRecentMaps(number)
                          for (const map of results.recentMaps) {
                            map.updated_at = dayjs(map.updated_at).format()
                          }
                          break
                        }
                      }
                      break
                    }
                  }
                })
              )
            }
            break
          }
        }
      })
    )
  }

  return {
    props: {
      pageConfig,
      headerConfig,
      footerConfig,
      session: await getSession(context),
      ...results
    }
  }
}

const Home = ({
  pageConfig,
  featuredLayers,
  popularLayers,
  recentLayers,
  featuredGroups,
  recentGroups,
  featuredMaps,
  recentMaps,
  headerConfig,
  footerConfig
}: Props): JSX.Element => {
  const { t } = useT()
  const router = useRouter()
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setLoaded(true)
  }, [])

  const renderCarousel = (
    config: Record<string, any>,
    key: string
  ): JSX.Element => {
    let collectionCards = []

    const shuffle = config.shuffle ? _shuffle : (data) => data

    const onClick = (link: string) => {
      //router.push(link)
      window.location.href = link
    }

    const featuredLayersCards = featuredLayers
      ? shuffle(
          featuredLayers.map((element) =>
            CardUtil.getLayerCard(element, element.layer_id, [], onClick)
          )
        )
      : []
    const featuredGroupsCards = featuredGroups
      ? shuffle(
          featuredGroups.map((element) =>
            CardUtil.getGroupCard(element, onClick)
          )
        )
      : []
    const featuredMapsCards = featuredMaps
      ? shuffle(
          featuredMaps.map((element) => CardUtil.getMapCard(element, onClick))
        )
      : []
    const popularLayersCards = popularLayers
      ? shuffle(
          popularLayers.map((element) =>
            CardUtil.getLayerCard(element, element.layer_id, [], onClick)
          )
        )
      : []

    const recentLayersCards = recentLayers
      ? shuffle(
          recentLayers.map((element) =>
            CardUtil.getLayerCard(element, element.layer_id, [], onClick)
          )
        )
      : []
    const recentGroupsCards = recentGroups
      ? shuffle(
          recentGroups.map((element) => CardUtil.getGroupCard(element, onClick))
        )
      : []
    const recentMapsCards = recentMaps
      ? shuffle(
          recentMaps.map((element) => CardUtil.getMapCard(element, onClick))
        )
      : []

    if (config.datasets) {
      const cards = config.datasets.map((dataset) => {
        const { type, filter } = dataset

        switch (type) {
          case 'layer': {
            if (filter === 'featured') return featuredLayersCards
            if (filter === 'popular') return popularLayersCards
            if (filter === 'recent') return recentLayersCards

            break
          }
          case 'group': {
            if (filter === 'featured') return featuredGroupsCards
            if (filter === 'recent') return recentGroupsCards

            break
          }
          case 'map': {
            if (filter === 'featured') return featuredMapsCards
            if (filter === 'recent') return recentMapsCards

            break
          }
          // No default
        }
      })

      if (cards && cards.length > 0) {
        collectionCards = CardUtil.combineCards(cards)
      }
    } else {
      // combine all the results
      collectionCards = CardUtil.combineCards([
        featuredLayersCards,
        featuredGroupsCards,
        featuredMapsCards,
        popularLayersCards,
        recentLayersCards,
        recentGroupsCards,
        recentMapsCards
      ])
    }

    const bgColor = config.bgColor ? config.bgColor : 'inherit'
    const style = config.style || {}
    const title = config.title ? t(config.title) : t('Trending')
    const carousel = (
      <Row
        key={key}
        style={{
          marginTop: '16px',
          marginBottom: '16px',
          backgroundColor: bgColor,
          ...style
        }}
      >
        <Row
          style={{
            height: '50px',
            width: '100%',
            textAlign: 'center',
            marginBottom: '16px'
          }}
        >
          <Title
            level={3}
            style={{
              lineHeight: '50px',
              width: '100%',
              marginTop: '0px'
            }}
          >
            {title}
          </Title>
        </Row>
        <ErrorBoundary>
          <Row>
            <CardCarousel
              cards={collectionCards}
              emptyMessage={config.emptyMessage}
              t={t}
            />
          </Row>
        </ErrorBoundary>
      </Row>
    )
    return carousel
  }
  const renderStories = (
    config: { title?: LocalizedString; style: React.CSSProperties },
    key: string
  ): JSX.Element => {
    let stories = []

    // TODO: implment Ghost storyfeed

    const title = config.title ? t(config.title) : t('Stories')

    const style = Object.assign(config.style || {}, {
      width: '100%'
    })

    if (stories.length > 0) {
      return (
        <Row key={key} style={style}>
          <Divider />
          <Row
            justify='center'
            style={{
              marginBottom: '20px',
              width: '100%'
            }}
          >
            <Col
              sm={24}
              md={12}
              style={{
                margin: '20px'
              }}
            >
              <Row
                justify='center'
                style={{
                  textAlign: 'center'
                }}
              >
                <Title level={3}>{title}</Title>
              </Row>
              {stories.map((story) => {
                return (
                  <Card
                    key={story.id}
                    style={{
                      maxWidth: '800px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginBottom: '20px',
                      border: '1px solid #ddd'
                    }}
                  >
                    <></>
                  </Card>
                )
              })}
            </Col>
          </Row>
        </Row>
      )
    }
  }

  if (!pageConfig || !pageConfig.components) {
    return <p>Invalid page config: {JSON.stringify(pageConfig)}</p>
  }

  return (
    <ErrorBoundary>
      <div
        style={{
          margin: 0,
          height: '100%'
        }}
      >
        <Layout
          title={t(pageConfig.title || 'Home')}
          headerConfig={headerConfig}
          footerConfig={footerConfig}
          hideFooter={pageConfig.disableFooter}
        >
          <div
            style={{
              margin: 0
            }}
          >
            {pageConfig.components.map((component, i) => {
              const key = `homepro-component-${component.id || i}`
              const style = component.style || {}

              if (!component.disabled) {
                switch (component.type) {
                  case 'map': {
                    return (
                      <HomePageMap
                        key={key}
                        map_id={component.map_id}
                        style={component.style}
                      />
                    )
                  }
                  case 'carousel': {
                    return renderCarousel(component, key)
                  }
                  case 'storyfeed': {
                    return renderStories(component, key)
                  }
                  case 'text': {
                    return (
                      <Row key={key} style={style}>
                        <div className='flow-text center align-center'>
                          {t(component.text)}
                        </div>
                      </Row>
                    )
                  }
                  case 'html': {
                    return (
                      <Row
                        key={key}
                        style={{
                          height: component.height || '100px',
                          ...style
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t(component.html)
                          }}
                        />
                      </Row>
                    )
                  }
                  case 'links':
                  case 'onboarding-links': {
                    return (
                      <Row
                        key={key}
                        style={{
                          backgroundColor: component.bgColor || 'inherit',
                          ...style
                        }}
                      >
                        <OnboardingLinks />
                      </Row>
                    )
                  }
                  case 'pro-links': {
                    return (
                      <Row
                        key={key}
                        style={{
                          backgroundColor: component.bgColor || 'inherit',
                          ...style
                        }}
                      >
                        <MapHubsProLinks />
                      </Row>
                    )
                  }
                  case 'slides': {
                    return (
                      <Row key={key} style={{ ...style }}>
                        <Slides config={component} />
                      </Row>
                    )
                  }

                  case 'button': {
                    return (
                      <HomePageButton
                        key={key}
                        label={component.label}
                        href={component.href}
                        style={component.style || {}}
                      />
                    )
                  }
                  default: {
                    return ''
                  }
                }
              }
            })}
          </div>
        </Layout>
      </div>
    </ErrorBoundary>
  )
}
export default Home
