import React from 'react'
import { Result, Button, Row } from 'antd'
import * as Sentry from '@sentry/react'
import useT from '@/hooks/useT'

// import icons individually for better dev build performance
import ReloadOutlined from '@ant-design/icons/ReloadOutlined'

function ErrorFallback({
  error
}: {
  error: {
    message: string
  }
}) {
  const { t } = useT()
  return (
    <Row
      style={{
        width: '100%',
        height: '100%',
        overflow: 'auto'
      }}
      align='middle'
      justify='center'
    >
      <Result
        status='error'
        title={t('Component has crashed')}
        subTitle={t(
          'Please try reloading the page, or contact support@maphubs.com'
        )}
        extra={[
          <Button
            key='reload'
            icon={<ReloadOutlined />}
            onClick={() => {
              location.reload()
            }}
          >
            Reload Page
          </Button>
        ]}
      >
        <p>{error?.message}</p>
      </Result>
    </Row>
  )
}

export default function ErrorBoundary({
  children
}: {
  children: React.ReactNode
}): JSX.Element {
  return (
    <Sentry.ErrorBoundary
      onError={(err) => {
        console.log(`Sentry Error Boundary saw error ${err}`)
      }}
      fallback={ErrorFallback}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
