import React from 'react'
import { Row, Col } from 'antd'
import LinkCard from './LinkCard'

// import icons individually for better dev build performance
import CompassOutlined from '@ant-design/icons/CompassOutlined'
import EnvironmentOutlined from '@ant-design/icons/EnvironmentOutlined'
import SearchOutlined from '@ant-design/icons/SearchOutlined'
import UserOutlined from '@ant-design/icons/UserOutlined'

const colProps = {
  xs: 24,
  sm: 12,
  md: 6
}

const OnboardingLinks = (): JSX.Element => {
  return (
    <div
      style={{
        width: '100%',
        backgroundColor: process.env.NEXT_PUBLIC_PRIMARY_COLOR,
        backgroundImage: 'url(/topo3.png)',
        backgroundBlendMode: 'screen'
      }}
    >
      <Row
        style={{
          width: '100%',
          maxWidth: '1024px',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '16px'
        }}
        gutter={[16, 16]}
      >
        <Col {...colProps}>
          <LinkCard title='Maps' href='/maps' icon={<CompassOutlined />} />
        </Col>
        <Col {...colProps}>
          <LinkCard
            title='Layers'
            href='/layers'
            icon={<EnvironmentOutlined />}
          />
        </Col>
        <Col {...colProps}>
          <LinkCard title='Groups' href='/groups' icon={<UserOutlined />} />
        </Col>
        <Col {...colProps}>
          <LinkCard title='Search' href='/search' icon={<SearchOutlined />} />
        </Col>
      </Row>
    </div>
  )
}
export default OnboardingLinks
